@import "../variables";

.color-white-hover:hover {
    color: $white !important;
}

.color-white:not(:hover) {
    color: $white !important;
}

.color-slate:not(:hover) {
    color: $slate !important;
}

.color-yellow-hover:hover {
    color: $yellow !important;
}

.color-yellow:not(:hover) {
    color: $yellow !important;
}

.color-blue-hover:hover {
    color: $blue !important;
}

.color-blue:not(:hover) {
    color: $blue !important;
}

.color-gray-hover:hover {
    color: $gray !important;
}

.color-gray:not(:hover) {
    color: $gray !important;
}

.color-slate-v1-hover:hover {
    color: $slate-v1 !important;
}

.color-slate-v1:not(:hover) {
    color: $slate-v1 !important;
}

.color-slate-v2-hover:hover {
    color: $slate-v2 !important;
}

.color-slate-v2:not(:hover) {
    color: $slate-v2 !important;
}

.color-blue-v1-hover:hover {
    color: $blue-v1 !important;
}

.color-blue-v1:not(:hover) {
    color: $blue-v1 !important;
}

.color-blue-v2-hover:hover {
    color: $blue-v2 !important;
}

.color-blue-v2:not(:hover) {
    color: $blue-v2 !important;
}

.color-red-hover:hover {
    color: $red !important;
}

.color-red:not(:hover) {
    color: $red !important;
}

.color-green-hover:hover {
    color: $green !important;
}

.color-green:not(:hover) {
    color: $green !important;
}
